
const images1 = [
    "gallery-1.jpg",
    "gallery-2.jpg",
    "gallery-3.jpg",
    "gallery-4.jpg",
    "gallery-5.jpg",
];

const images2 = [
    "gallery-6.jpg",
    "gallery-7.jpg",
    "gallery-8.jpg",
    "gallery-9.jpg",
    "gallery-10.jpg",
];

function Gallery() {
    

    return (
    <section id="gallery" class="section-bg-color">
		
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="section-title">Galería de fotos</h1>
                </div>
            </div>
        </div>
        
        <div class="gallery-wrapper">
            <div class="gallery-left"><i class="fas fa-chevron-left"></i></div>
            <div class="gallery-right"><i class="fas fa-chevron-right"></i></div>
            
            <div class="gallery-scroller">
                <ul>
                    {
                        images1.map(val => 
                            <li>
                                <div class="hover-info">
                                    <a class="btn btn-light btn-sm only-icon" href={"/images/" + val} data-lightbox="WeddingPhotos" title="Wedding Photos">
                                        <i class="fa fa-link"></i>
                                    </a>
                                </div>
                                <img src={"/images/Small" + val} alt="" style={{objectFit: "cover"}}/>
                            </li>
                        )
                    }
                </ul>
                <ul>
                    {
                        images2.map(val => 
                            <li>
                                <div class="hover-info">
                                    <a class="btn btn-light btn-sm only-icon" href={"/images/" + val} data-lightbox="WeddingPhotos" title="Wedding Photos">
                                        <i class="fa fa-link"></i>
                                    </a>
                                </div>
                                <img src={"/images/Small" + val} alt="" style={{objectFit: "cover"}}/>
                            </li>
                        )
                    }
                </ul>

                {/* <ul>
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                    
                    <li>
                        <div class="hover-info">
                            <a class="btn btn-light btn-sm only-icon" href="https://via.placeholder.com/1920x1080.png?text=Gallery+Image+Fullsize" data-lightbox="WeddingPhotos" title="Wedding Photos">
                                <i class="fa fa-link"></i>
                            </a>
                        </div>
                        <img src="https://via.placeholder.com/635x635.png" alt="" />
                    </li>
                </ul> */}
            </div>
        </div>
    </section>
    );
}

export default Gallery;