/* eslint-disable no-undef */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */


//RTL support
var rtl = false;                                //Set to true if your website is RTL. Otherwise, keep it false.

//ONE-PAGE NAVIGATION HIGHLIGHT
var onepage_nav = true;                         //If true, each menu item will highlight when scrolling to each respective section.

//MOBILE MENU TITLE
var mobile_menu_title = "Menu";                 //The title of the mobile menu.

//HERO FULLSCREEN VARIABLE
var hero_full_screen = true;                    //If true, the hero section will fit to screen size. If false, hero height will be the height of its content.

//HERO BACKGROUND SLIDESHOW IMAGES
var slidehow_images = [
    "/images/main-hero-1.jpg",
    "/images/main-hero-2.jpg",
];

//TIMELINE PARALLAX
var timeline_parallax = true;                   //If false, the timeline items will not move on mousemove.


//COUNTDOWN VARIABLES
var c_days = "DAYS";                            //Countdown "Days" label
var c_hours = "HOURS";                          //Countdown "Hours" label
var c_minutes = "MIN.";                         //Countdown "Minutes" label
var c_seconds = "SEC.";                         //Countdown "Seconds" label
var countdown_end_msg = "Event Started!";       //Message to display when the countdown reaches the end


var Neela = {

    initialized: false,
    cDays: c_days,
    cHours: c_hours,
    cMinutes: c_minutes,
    cSeconds: c_seconds,
    countdownEndMsg: countdown_end_msg,
    sendingMail: false,
    heroFullScreen: hero_full_screen,
    mobMenuFlag: false,
    mobileMenuTitle: mobile_menu_title,
    onepageNav: onepage_nav,
    rtlFlag: rtl,
    slidehowImages: slidehow_images,
    timelineParallax: timeline_parallax,

    init: function () {

        var $_self = this;

        if ($_self.initialized) {
            return;
        }

        $_self.initialized = true;
        $_self.build();
        $_self.events();
    },

    build: function () {

        var $_self = this;

        /**
        * Create Neela Style elements
        */
        $_self.neelaStyle();

        /**
        * Navigation
        */
        $_self.navigation();

        /**
        * Dinamically create the menu for mobile devices
        */
        $_self.createMobileMenu();

        /**
        * Fit hero to screen size
        */
        $_self.heroHeight();

        /**
        * Activate placeholder in older browsers
        */
        $("input, textarea").placeholder();

        /**
        * Create Lightbox Gallery links
        */
        $_self.createLightboxGallery();

        /**
        * Create Hero Background Slideshow
        */
        $_self.createBackgroundSlideshow();

        /**
        * Create Owl Sliders
        */
        $_self.createOwlSliders();

        /**
        * Create Gallery
        */
        $_self.createGallery();

        /**
        * Create the Hero background image grid
        */
        $_self.bgImageGrid();

        /**
        * Create Countdown
        */
        $_self.countdown();

        /**
        * Initiate Parallax
        */
        $_self.parallaxBg();
    },

    events: function () {

        var $_self = this;
        var isLoadedVid;
        var isLoadedAnim;

        /**
        * Functions called on window resize
        */
        $_self.windowResize();

        /**
        * Resize embed videos
        */
        isLoadedVid = setInterval(function () {
            if (/loaded|complete/.test(document.readyState)) {
                clearInterval(isLoadedVid);
                $_self.resizeVideos();
            }
        }, 10);

        /**
        * Capture object events
        */
        $_self.objEvents();

        /**
        * Timeline Parallax Effect
        */
        $_self.parallaxTimeline();

        /**
        * Animate elements on scrolling
        */
        isLoadedAnim = setInterval(function () {
            if (/loaded|complete/.test(document.readyState)) {
                clearInterval(isLoadedAnim);
                $_self.animateElems();
            }
        }, 10);
    },

    neelaStyle: function () {
        $(".neela-style, .btn.btn-primary, .btn.btn-light, .btn.btn-dark").prepend("<span class=\"h-lines\"></span><span class=\"v-lines\"></span>");
    },

    preloader: function () {
        $("#preloader").fadeOut(1000);
    },

    navigation: function () {

        var $_self = this;
        var sticky;

        $(".nav li a").on("click", function (event) {
            var navActive = $(this);
            var scroll = 0;

            if ($.browser.mobile && (!navActive.closest(".dropdown").hasClass("open") || navActive.closest(".dropdown-menu").css("display") === "block" || !navActive.parent().parent().hasClass("nav"))) {
                event.preventDefault();
                return false;
            }

            if (navActive.attr("href").charAt(0) === "#" && $(navActive.attr("href")).length) {
                event.preventDefault();

                if (navActive.attr("href") !== "#hero" && $(navActive.attr("href")).offset() !== null) {
                    scroll = $(navActive.attr("href")).offset().top - 55;
                }

                $("html, body").stop().animate({
                    scrollTop: scroll
                }, 150, "easeOutExpo", function () {
                    navActive.blur();
                });
            } else {
                window.open(navActive.attr("href"), "_self");
            }
        });

        if (window.Waypoint !== undefined) {
            sticky = new Waypoint.Sticky({
                element: $(".nav-section"),
                offset: -1
            });

            sticky = sticky;
        }

        if ($(".nav-section.light").length) {
            $(window).on("scroll load", function () {
                var s = $(window).scrollTop();

                if (s > 180) {
                    $(".nav-section.light").addClass("sticky");
                } else {
                    $(".nav-section.light").removeClass("sticky");
                }
            });
        }

        if ($("#wrapper > section, #wrapper > div#hero").length !== 0 && $_self.onepageNav && jQuery().waypoint) {
            $("#wrapper > section, #wrapper > div#hero").waypoint({
                element: $("#wrapper > section"),
                handler: function (direction) {
                    var $elem = $(this);
                    var id = $elem[0].element.id;

                    if (direction === "up") {
                        id = $elem[0].element.previousElementSibling.id;
                    }

                    $(".nav a").removeClass("active");

                    if ($(window).scrollTop() < 100) {
                        $(".nav a[href=\"#hero\"]").addClass("active");
                    } else {
                        $(".nav a[href=\"#" + id + "\"]").addClass("active");
                    }
                },
                offset: "50%"
            });
        }

        /*$(window).on("load", function () {
            var hash = location.hash.replace("#", "");

            if (hash !== "") {
                location.hash = "";
                $("html, body").stop().animate({
                    scrollTop: $("#" + hash).offset().top - 65
                }, 1500, "easeInOutExpo");
            }

            if (window.Waypoint !== undefined) {
                sticky = new Waypoint.Sticky({
                    element: $(".nav-section")
                });
            }
        });/*/
    },

    createMobileMenu: function (w) {

        var $_self = this;
        var $wrapper = $("#wrapper");
        var $navMobile;
        var etype;

        if ($.browser.mobile) {
            etype = "touchstart";
        } else {
            etype = "click";
        }

        if (w !== null) {
            w = $(window).innerWidth();
        }

        if (w <= 975 && !$_self.mobMenuFlag) {

            $("body").prepend("<nav class=\"nav-mobile\"><i class=\"fa fa-times\"></i><h2>" + $_self.mobileMenuTitle + "</h2><ul></ul></nav>");

            $(".nav-mobile > ul").html($(".nav").html());

            $(".nav-mobile b, .nav-mobile .nav-logo").remove();

            $(".nav-mobile ul.dropdown-menu").removeClass().addClass("dropdown-mobile");

            if ($(".navbar > a.btn").length) {
                $(".navbar > a.btn").each(function () {
                    $(".nav-mobile").append($(this).clone());
                });

                $(".nav-mobile > a.btn").removeClass("btn-light").addClass("btn-primary btn-sm");
            }

            $navMobile = $(".nav-mobile");

            $("#nav-mobile-btn").on(etype, function (e) {
                e.stopPropagation();
                e.preventDefault();

                setTimeout(function () {
                    $wrapper.addClass("open");
                    $navMobile.addClass("open");
                }, 25);

                $(document).on(etype, function (e) {
                    if (!$(e.target).hasClass("nav-mobile") && !$(e.target).parents(".nav-mobile").length) {
                        $wrapper.removeClass("open");
                        $navMobile.removeClass("open");
                        $(document).off(etype);
                    }
                });

                $(">i", $navMobile).on(etype, function () {
                    $wrapper.removeClass("open");
                    $navMobile.removeClass("open");
                    $(document).off(etype);
                });
            });

            $_self.mobMenuFlag = true;

            $(".nav-mobile li a").on("click", function (event) {
                var navActive = $(this);
                var scroll = 0;

                if (navActive.attr("href") !== "#hero") {
                    scroll = $(navActive.attr("href")).offset().top - 65;
                }

                $("html, body").stop().animate({
                    scrollTop: scroll
                }, 150, "easeInOutExpo", function () {
                    navActive.blur();
                });

                $wrapper.removeClass("open");
                $navMobile.removeClass("open");
                $(document).off(etype);

                event.preventDefault();
            });
        }
    },

    heroHeight: function () {

        var $_self = this;

        if ($_self.heroFullScreen) {
            $("#hero").css({minHeight: $(window).innerHeight() + "px"});

            $(window).resize(function () {
                var padding = parseInt($("#hero").css("padding-bottom")) + 70;
                var nextMargin = parseInt($("#hero").next("section").css("margin-top"));
                var wHeight = $(window).innerHeight() - padding;
                var cHeight = $("#hero >.container").height();
                var dif;
                var xtraMT = -10;

                if (nextMargin < 0 && !Number.isNaN(nextMargin)) {
                    wHeight += nextMargin + padding;
                }

                dif = wHeight - cHeight;

                if ($(".nav-section.light").length) {
                    xtraMT = 10;
                }

                if (dif > 0 && $(".v-center").length) {
                    $("#hero >.container").css({"margin-top": (((dif) / 2) + xtraMT) + "px"});
                }

                $("#hero").css({minHeight: $(window).innerHeight() + "px"});
            });
        }
    },

    bgImageGrid: function () {

        if ($("#freewall").length) {
            $("#freewall .item").each(function () {
                var $item = $(this);
                $item.width(Math.floor(260 + 200 * Math.random()));
                $item.css({"background-image": "url(" + $(">img", $item).attr("src") + ")"});
                $(">img", $item).remove();
            });

            $("#freewall").appendTo("#wrapper");

            $(document).ready(function () {
                var wall = new Freewall("#freewall");
                wall.reset({
                    selector: ".item",
                    animate: false,
                    cellW: 20,
                    cellH: 320,
                    gutterX: 1,
                    gutterY: 1,
                    onResize: function () {
                        wall.fitWidth();
                    }
                });
                wall.fitWidth();
            });
        }
    },

    createBackgroundSlideshow: function () {
        var $_self = this;

        if ($(".bg-slideshow").length && $_self.slidehowImages.length) {
            $(".bg-slideshow").zoomSlider({
                src: $_self.slidehowImages,
                bullets: false,
                speed: 10000,
                switchSpeed: 1000,
                interval: 6000
            });
        }
    },

    createLightboxGallery: function () {

        if (window.lightbox !== undefined) {
            lightbox.option({
                "resizeDuration": 200,
                "wrapAround": true,
                "disableScrolling": true,
                "showImageNumberLabel": false,
                "positionFromBottom": 150
            });
        }
    },

    createOwlSliders: function () {
        var $_self = this;

        if ($(".timeline-gallery").length) {
            $(".timeline-gallery").owlCarousel({
                nav: true,
                dots: false,
                responsive: {
                    "0": {
                        items: 1
                    }
                },
                rtl: $_self.rtlFlag
            });
        }

        if ($(".testimonials").length) {
            $(".testimonials").owlCarousel({
                nav: false,
                dots: true,
                responsive: {
                    "0": {
                        items: 1
                    }
                },
                rtl: $_self.rtlFlag
            });
        }
    },

    createGallery: function () {

        var $gallery = $(".gallery-scroller");
        var scrolling = false;

        if ($(".gallery-scroller").length) {

            $(".gallery-right").on("click", function () {
                if (scrolling) {
                    return false;
                }

                scrolling = true;
                $gallery.animate({scrollLeft: $gallery.scrollLeft() + 380}, function () {
                    scrolling = false;
                });
            });

            $(".gallery-left").on("click", function () {
                if (scrolling) {
                    return false;
                }

                scrolling = true;
                $gallery.animate({scrollLeft: $gallery.scrollLeft() - 380}, function () {
                    scrolling = false;
                });
            });

            $(document).ready(function () {
                $(".gallery-scroller").niceScroll({
                    cursorcolor: "#fff",
                    cursorwidth: "0px",
                    background: "#fff",
                    cursorborder: "0px solid #1F2326",
                    zindex: "999",
                    autohidemode: false,
                    enablemousewheel: false,
                    touchbehavior: true
                });
            });
        }
    },

    countdown: function () {
        var $_self = this;
        var changeTime;

        if ($(".countdown").length) {

            changeTime = function (counter, $parent, future) {
                var today = new Date();
                var _dd = future - today;
                var _dday;
                var _dhour;
                var _dmin;
                var _dsec;

                if (_dd < 0) {
                    $parent.html("<div class=\"end\">" + $_self.countdownEndMsg + "</div>");
                    clearInterval(counter);

                    return false;
                }

                _dday = Math.floor(_dd / (60 * 60 * 1000 * 24) * 1);
                _dhour = Math.floor((_dd % (60 * 60 * 1000 * 24)) / (60 * 60 * 1000) * 1);
                _dmin = Math.floor(((_dd % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) / (60 * 1000) * 1);
                _dsec = Math.floor((((_dd % (60 * 60 * 1000 * 24)) % (60 * 60 * 1000)) % (60 * 1000)) / 1000 * 1);

                $(".days > div", $parent).html(_dday);
                $(".hours > div", $parent).html(_dhour);
                $(".minutes > div", $parent).html(_dmin);
                $(".seconds > div", $parent).html(_dsec);
            };

            $(".countdown").each(function () {
                var counter;
                var $parent = $(this);
                var future = new Date($parent.data("date"));
                var html = "";

                if (future && Object.prototype.toString.call(future) === "[object Date]" && !Number.isNaN(future)) {
                    html = "<div class=\"days\"><div></div><span>" + $_self.cDays + "</span></div>" +
                    "<div class=\"hours\"><div></div><span>" + $_self.cHours + "</span></div>" +
                    "<div class=\"minutes\"><div></div><span>" + $_self.cMinutes + "</span></div>" +
                    "<div class=\"seconds\"><div></div><span>" + $_self.cSeconds + "</span></div>";

                    $parent.html(html);
                }

                counter = setInterval(function () {
                    changeTime(counter, $parent, future);
                }, 1000);
            });
        }
    },

    parallaxBg: function () {

        var $_self = this;

        if (!$.browser.mobile && $(window).innerWidth() > 992) {

            $(window).on("scroll", function () {
                var scrolled = $(window).scrollTop();

                $(".parallax-background").each(function () {
                    var $elem = $(this);
                    var initY = $elem.offset().top;
                    var height = $elem.outerHeight();
                    var visible = $_self.isInViewport(this);
                    var diff;
                    var ratio;

                    if (visible) {
                        diff = scrolled - initY;
                        ratio = Math.round((diff / height) * 100);
                        $elem.css("background-position", "center " + parseInt(-(ratio * (height / 250))) + "px");
                    }
                });
            });

        } else {
            $(".parallax-background").css({"background-position": "50% 50%", "background-size": "cover", "background-attachment": "scroll"});
        }
    },

    isInViewport: function (elem) {
        var obj = elem.getBoundingClientRect();

        return (
            (obj.height > 0 || obj.width > 0) &&
            obj.bottom >= 0 &&
            obj.right >= 0 &&
            obj.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            obj.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    },

    windowResize: function () {

        var $_self = this;

        $(window).resize(function () {
            var w = $(window).innerWidth();

            $_self.createMobileMenu(w);

            $_self.blogMetas();

            if ($(window).innerWidth() < 751) {
                $(".navbar > a.btn").addClass("btn-sm");
                $(".navbar > a.btn").width("auto");
            }
        });
    },

    resizeVideos: function () {

        var $allVideos = $("iframe[src^=\"http://player.vimeo.com\"], iframe[src^=\"https://player.vimeo.com\"], iframe[src^=\"http://www.youtube.com\"], iframe[src^=\"https://www.youtube.com\"], object, embed");

        $allVideos.each(function () {
            var $el = $(this);
            var aspRatio = $el.attr("height") / $el.attr("width");

            if (aspRatio < 0.3 || aspRatio > 0.8 || Number.isNaN(aspRatio)) {
                aspRatio = 0.559;
            }

            $el.attr("data-aspectRatio", aspRatio).removeAttr("height").removeAttr("width");
        });

        $(window).resize(function () {

            $allVideos.each(function () {
                var $el = $(this);
                var newWidth = $el.parent().width();

                $el.width(newWidth).height(newWidth * $el.attr("data-aspectRatio"));
            });

        }).resize();
    },

    showError: function (err = "") {
        var $_self = this;

        if (err === "") {
            err = $_self.contactFormErrorMsg;
        }

        $(".form_status_message").html("<div class=\"alert alert-danger alert-dismissible fade show\" role=\"alert\">" + err + "<button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"alert\" aria-label=\"Close\"></button></div>");
    },

    objEvents: function () {

        //Fix buttons width (round to integer)
        $(".btn").each(function () {
            var $btn = $(this);
            var w = $btn.width();
            var xtr = 2;
            var len = $btn.text().split(" ").length;

            if (len > 2 || $btn.find("i").length !== 0) {
                xtr = 15;
            } else if (len > 1) {
                xtr = 8;
            }

            $btn.width(Math.round(w) + xtr);
        });

        //Rotate heart divider on mouseenter about element
        $("#about-us .element .image").on("mouseenter", function () {
            var $elem = $(this);

            if ($elem.parent().is(":first-child") && !$(">.divider-about-us", $elem.closest(".row")).hasClass("flip")) {
                return false;
            }

            if (!$elem.hasClass("flip")) {
                if ($("#about-us .element .image.flip").length) {
                    $("#about-us .element .image").toggleClass("flip");
                } else {
                    $elem.toggleClass("flip");
                }

                $(">.divider-about-us", $elem.closest(".row")).toggleClass("flip");
            }
        });

        //Slide map info on mouseenter map_canvas
        $("#map_canvas").on("mouseenter", function () {
            $(".location-info").addClass("open");
        }).on("mouseleave", function () {
            $(".location-info").removeClass("open");
        });

        // Scroll effect of navigation logo and .scrollto buttons
        $(".nav-logo, .scrollto").on("click", function (event) {
            var $navActive = $(this);
            var scroll = 0;
            var href = $navActive.attr("href");

            if (/#/.test(href) && $(href).length) {

                event.preventDefault();

                if (href !== "#hero") {
                    scroll = $(href).offset().top - 65;
                }

                $("html, body").stop().animate({
                    scrollTop: scroll
                }, 150, "easeInOutExpo", function () {
                    $navActive.blur();
                });
            }
        });

        //Element V2 image handler
        if ($(".element-v2").length) {
            $(".element-v2").each(function () {
                var $elem = $(">.image", $(this));
                $elem.css({"background-image": "url(" + $(">img", $elem).attr("src") + ")"});
                $(">img", $elem).hide();
            });
        }

        if ($(".overflow-image").length) {
            $(".overflow-image").each(function () {
                var $elem = $(this);
                $elem.css({"background-image": "url(" + $(">img", $elem).attr("src") + ")"});
            });
        }

        if ($(".progress").length) {
            $(".progress").waypoint(function () {
                $(".progress").each(function () {
                    $("> .progress-bar", $(this)).delay(300).queue(function (next) {
                        var $elem = $(this);
                        $elem.css("width", $elem.attr("aria-valuenow") + "%");
                        next();
                    });
                });
            }, {
                triggerOnce: true,
                offset: "bottom-in-view"
            });
        }
    },

    parallaxTimeline: function () {

        var $_self = this;
        var tp;

        if ($_self.timelineParallax) {
            tp = function (e) {
                $("> div", this).each(function () {

                    var $obj = $(this);
                    var val = $obj.attr("data-parallax");
                    var x = (e.clientX * val) / 300;
                    var y = (e.clientY * val) / 300;

                    $obj.css({
                        "-webkit-transform": "translateX(" + x + "px) translateY(" + y + "px)",
                        "-moz-transform": "translateX(" + x + "px) translateY(" + y + "px)",
                        "-ms-transform": "translateX(" + x + "px) translateY(" + y + "px)",
                        "-o-transform": "translateX(" + x + "px) translateY(" + y + "px)",
                        "transform": "translateX(" + x + "px) translateY(" + y + "px)"
                    });
                });
            };

            if ($(window).innerWidth() > 992) {
                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    var h = $(window).height();

                    $(".timeline [class^=\"template-\"]").each(function () {
                        var $obj = $(this);
                        var offset = $obj.offset().top;
                        var timelineH = $obj.height();

                        if ((s + h) >= offset && s <= (offset + timelineH)) {
                            $obj.on("mousemove", tp);
                        } else {
                            $obj.off("mousemove", tp);
                        }

                    });
                });
            }
        }
    },

    blogMetas: function () {
        var $elems = $(".info-blog .bottom-info, .post-content .bottom-info");

        if ($elems.length) {
            $elems.each(function () {
                var $obj = $(this);

                if ($obj.height() > 35) {
                    $obj.addClass("center");
                } else {
                    $obj.removeClass("center");
                }
            });
        }
    },

    animateElems: function () {

        var animate = function () {
            $("[data-animation-delay]").each(function () {
                var $this = $(this);
                var s = $(window).scrollTop();
                var h = $(window).height();
                var d = parseInt($this.attr("data-animation-delay"), 10);
                var dir = $this.data("animation-direction");

                if (dir === undefined) {
                    return false;
                }

                $this.addClass("animate-" + dir);

                $(document).ready(function () {
                    if ((s + h) >= $this.offset().top) {
                        if (Number.isNaN(d) || d === 0) {
                            $this.removeClass("animate-" + dir).addClass("animation-" + dir);
                        } else {
                            setTimeout(function () {
                                $this.removeClass("animate-me").addClass("animation-" + dir);
                            }, d);
                        }
                    }
                });
            });
        };

        if ($(window).innerWidth() >= 751) {
            $(window).scroll(function () {
                animate();
            });

            animate();
        } else {
            $("[data-animation-delay]").addClass("visible");
        }
    }
};

export default Neela;