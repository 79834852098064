
function AboutUs() {
    return (
        <section id="about-us">
			<div class="container">
				<div class="row about-elems-wrapper">
					<div class="element col-md-6 col-xl-4 offset-xl-2" data-animation-direction="from-left" data-animation-delay="300">
						<div class="image">
							<img src="/images/groom.jpg" alt="" />
							<div class="hover-info neela-style">
								<div class="content">
									<h3>Axel<small>El novio</small></h3>
									<p>"Athziri es la mujer de corazon más puro y que puede hacer cualquier momento algo especial"</p>
									{/* <ul class="sn-icons">
										<li><a href="#"><i class="fab fa-instagram-square"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter-square"></i></a></li>
										<li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					
					<div class="divider-about-us" data-animation-direction="fade" data-animation-delay="500">
						<i class="icon-two-hearts"></i>
					</div>
					
					<div class="element col-md-6 col-xl-4" data-animation-direction="from-right" data-animation-delay="400">
						<div class="image">
							<img src="/images/bride.jpg" alt="" />
							<div class="hover-info neela-style">
								<div class="content">
									<h3>Athziri<small>La novia</small></h3>
									<p>"Axel ha sido la persona que más me ha motivado y con quien disfruto vivir cada momento"</p>
									{/* <ul class="sn-icons">
										<li><a href="#"><i class="fab fa-instagram-square"></i></a></li>
										<li><a href="#"><i class="fab fa-twitter-square"></i></a></li>
										<li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
				</div>
		
				<div class="row">
					<div class="about-us-desc col-lg-8 offset-lg-2" data-animation-direction="from-bottom" data-animation-delay="300">
						<h3><small>¡Nos vamos </small>a casar!</h3>
						<p>Nos conocimos hace 5 años, sin imaginarnos todas las experiencias que íbamos a vivir juntos. Después de tantos kilómetros, viajes, conciertos y aventuras, queremos festejar y agradecerles ser parte de nuestra historia.</p>
					</div>
				</div>
			</div>
		</section>
    );
}

export default AboutUs;