import { useEffect } from "react";
import Preloader from "../components/Preloader";
import Neela from '../services/NelaScripts';


function LandingPage() {

	useEffect(() => {
		Neela.heroHeight();
		Neela.animateElems();
	}, []);

    return (
		<>
			<Preloader></Preloader>
			<section id="hero" class="bg-slideshow">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
						
							<div class="hero-wrapper v-center x-pad">
								<h2 data-animation-direction="fade" data-animation-delay="600">Save the Date</h2>
								
								<h1 class="hero-title light ">
									<span data-animation-direction="from-right" data-animation-delay="300">Athziri</span>
									<small data-animation-direction="from-top" data-animation-delay="300">&</small> 
									<span data-animation-direction="from-left" data-animation-delay="300">Axel</span>
								</h1>
								
								<div class="hero-subtitle light" data-animation-direction="fade" data-animation-delay="1000">MARZO <span>11</span> 2023</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
		</>
    );
}

export default LandingPage;