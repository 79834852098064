
function Footer() {
    return (
        <footer id="footer-onepage" class="bg-color">
			<div class="footer-widget-area">
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<div class="footer-info left">
							Sábado 11 de Marzo del 2023 a las 06:30 pm<br/>
								"Mansión Magnolia" Calle Francisco I. Madero 636, 44100 Guadalajara, Jalisco
							</div>
						</div>
						
						<div class="col-md-4">
							<div class="footer-logo">
								Athziri<br/>
								<small>&</small><br/>
								Axel
							</div>
						</div>
						
						<div class="col-md-4">
							<div class="footer-info right">
								Tel.: 3316034699 y 3334739465
							</div>
						</div>
					</div>
				</div>
			</div>
		
			{/* <div class="copyright">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							&copy; 2021 <a href="https://www.wiselythemes.com">WiselyThemes</a>, All Rights Reserved.
						</div>
					</div>
				</div>
			</div> */}
		</footer>
    );
}

export default Footer;