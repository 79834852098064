function MainBanner() {
    return (
        <section id="hero" class="bg-slideshow section-divider-bottom-1">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
					
						<div class="hero-wrapper v-center">
							<h2 data-animation-direction="fade" data-animation-delay="600">Save the Date</h2>
							
							<h1 class="hero-title light ">
								<span data-animation-direction="from-right" data-animation-delay="300">Athziri</span>
								<small data-animation-direction="from-top" data-animation-delay="300">&</small> 
								<span data-animation-direction="from-left" data-animation-delay="300">Axel</span>
							</h1>
							
							<div class="hero-subtitle light" data-animation-direction="fade" data-animation-delay="1000">MARZO <span>11</span> 2023</div>
							
							<div data-animation-direction="fade" data-animation-delay="1000">
								<a href="#the-wedding" class="btn btn-light scrollto">invitación</a>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>
    );
}

export default MainBanner;