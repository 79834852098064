function Navbar() {
    return (
        <header id="header">
			<div class="nav-section">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							<a href="#hero" class="nav-logo"><img src="images/logo4.png" alt="logoAxA" /></a>
							
							<nav class="navbar">
								
								<ul class="nav navbar-nav">
									<li><a href="#hero">Inicio</a></li>
									
									<li><a href="#about-us" data-toggle="dropdown" data-hover="dropdown">Something about us</a></li>

                                    <li><a href="#the-wedding">Invitación</a></li>

                                    <li><a href="#location">Ubicación</a></li>

                                    <li><a href="#rsvp">Confirma tu asistencia</a></li>

                                    <li><a href="#gallery">Galería</a></li>
								</ul>
								
								<button id="nav-mobile-btn"><i class="fas fa-bars"></i></button>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</header>
    );
}

export default Navbar;