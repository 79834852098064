/* eslint-disable no-undef */
import { useRef, useEffect } from "react";

function Map({
    center,
    zoom,
  }) {
    const ref = useRef();
  
    useEffect(() => {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });

      new google.maps.Marker({
        position: center,
        map: map,
      });
    });
  
    return <div ref={ref} id="map" style={{height: '100%'}}/>;
}

export default Map;