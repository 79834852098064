/* eslint-disable no-restricted-globals */

import axios from "axios";
import { useState } from "react";

function Confimation(props) {
    const [weddingInfo, setWeddingInfo] = useState(props.weddingInfo);
    const [loading, setLoading] = useState(false);

    var confirm = () => {
        setLoading(true);
        event.preventDefault();
        var body = {
            guests: weddingInfo.guests
        };

        console.log(body);

        axios.post('https://app-azure-function-wedding-220722194040.azurewebsites.net/api/Confirmation?id=' + weddingInfo.invitation.id, body)
            .then(result =>  {
                weddingInfo.invitation.isConfirmed = true;
                setWeddingInfo({...weddingInfo});
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    };

    var handleGuestCheck = (guestId) => {
        weddingInfo.guests.forEach(g => {
            if (g.id === guestId) g.isAttending = !g.isAttending;
        });

        setWeddingInfo({...weddingInfo});
    };

    // var handleConfirmationCheck = (value) => {
    //     weddingInfo.guests.forEach(g => g.isAttending = value);

    //     setWeddingInfo({...weddingInfo});
    // };

    return (
    <section id="rsvp" class="section-bg-color extra-padding-section">
        <div class="container">
            
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2  col-xxl-6 offset-xxl-3">
                    
                    <div class="form-wrapper flowers neela-style">
                        <h3 class="section-title">Confirma tu asistencia <b>{weddingInfo?.invitation?.name}</b></h3>
                        {loading ? <div class="loading-heart" style={{marginTop:"80px", position:"relative"}}>
                        <svg viewBox="0 0 512 512" width="100">
                            <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" />
                        </svg>
                    </div> : 
                        <form id="form-rsvp" onSubmit={() => confirm()}>
                            {weddingInfo?.invitation?.isConfirmed ? 
                                <div class="center"><h4>Gracias por confirmar tu asistencia.</h4></div> :
                                <div class="center"><h5>Por favor selecciona las personas que asistirán al evento.</h5></div>
                            }
                        <fieldset class="form-check-wrapper required" name="Meal Preferences" id="meal_pref">
                            {weddingInfo?.guests?.map(g => 
                                <div class="form-check form-control-lg" key={g.id}>
                                    <input class="form-check-input" type="checkbox" value={g.isAttending} checked={g.isAttending} id={g.id} onChange={() => handleGuestCheck(g.id)}/>
                                    <label for={g.id}>{g.name}</label>
                                </div>
                            )}
                        </fieldset>
                        {/* {weddingInfo?.invitation?.isConfirmed ? <div></div> :
                            <div class="row form-check-wrapper">
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input required" type="radio" name="Attend wedding" id="attend_wedding_yes" onChange={() => handleConfirmationCheck(true)}/>
                                        <label for="attend_wedding_yes">Si, confirmo mi asistencia.</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input required" type="radio" name="Attend wedding" id="attend_wedding_no" onChange={() => handleConfirmationCheck(false)}/>
                                        <label for="attend_wedding_no">Lo siento, no podré asistir.</label>
                                    </div>
                                </div>
                            </div>
                        } */}
                        <div class="row form-check-wrapper">
                        {weddingInfo?.invitation?.isConfirmed ?
                            <div class="center">
                                <button type="submit" class="btn btn-secondary submit_form">Actualizar</button>
                            </div>
                            :
                            <div class="center">
                                <button type="submit" class="btn btn-primary">Enviar</button>
                            </div>
                        }
                        </div>
                     
                        <div class="row form-check-wrapper">
                            <div class="col">
                                    <p><i>Adoramos a sus hijos, pero creemos que necesitan una noche libre. Sólo adultos, por favor.</i></p>
                                
                            </div>
                        </div>
                        
                    </form>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Confimation;