import { useEffect } from 'react';
import AboutUs from '../components/AboutUs';
import Confimation from '../components/Confirmation';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Invitation from '../components/Invitation';
import Location from '../components/Location';
import MainBanner from '../components/MainBanner';
import Navbar from '../components/Navbar';
import Preloader from '../components/Preloader';
import Neela from '../services/NelaScripts';

function InvitationPage(props) {

    useEffect(() => {
        Neela.init();
        Neela.preloader();
    }, []);

    return (
        <>
            <Preloader></Preloader>
            <Navbar></Navbar>
            <MainBanner></MainBanner>
            <AboutUs></AboutUs>
            <Invitation></Invitation>
            <Location></Location>
            <Confimation weddingInfo={props.weddingInfo}></Confimation>
            <Gallery></Gallery>
            <Footer></Footer>
        </>
    );
}

export default InvitationPage;