function Invitation() {
    return (
        <section id="the-wedding" class="parallax-background bg-color-overlay padding-divider-top section-divider-bottom-1">
			<div class="section-divider-top-1 off-section"></div>
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<h1 class="section-title light">Invitación</h1>
					</div>
				</div>
				
				<div class="row">
					<div class="col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 center">
						<div class="invite neela-style" data-animation-direction="from-left" data-animation-delay="100">
							<div class="invite_title">
								<div class="text">
									Save<small>the</small>Date
								</div>
							</div>
							
							<div class="invite_info">
								<h2>Athziri <small>&</small> Axel</h2>
								
								<div class="uppercase">Tenemos el honor de invitarles a nuestra boda</div>
								<div class="date">Sábado 11 de Marzo del 2023<small>a las 06:30 pm</small></div>
								<div class="uppercase">En<br/><b>Mansión Magnolia</b> Calle Francisco I. Madero 636, 44100 Guadalajara, Jalisco</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
}

export default Invitation;