import { Wrapper } from "@googlemaps/react-wrapper";
import Map from "./Map";


function Location() {

    const center = { lat: 20.673514068159324, lng: -103.35429753190148 };
    const zoom = 15;


    return (
    <section id="location">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2 class="section-title">Ubicación</h2>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-xl-10 offset-xl-1">
                    <div class="map-info-container">
                        <div class="info-wrapper" data-animation-direction="from-bottom" data-animation-delay="100">
                            <div class="location-info">
                                <div class="neela-style">
                                    <h4 class="has-icon"><i class="icon-champagne-glasses"></i>Inicio<small>06:30pm</small></h4>
                                    <h5>Mansión Magnolia</h5>
                                    <p>Calle Francisco I. Madero 636, 44100 <br/>Guadalajara, Jalisco</p>
                                    <p><b>Código de vestimenta: Formal</b></p>
                                </div>
                            </div>
                        </div>

                        
                        
                        <div class="map-wrapper" data-animation-direction="fade" data-animation-delay="100">
                            <div id="map_canvas" class="gmap">
                                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
                                    <Map center={center} zoom={zoom} />
                                </Wrapper>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div class="center">
                        <a href="https://goo.gl/maps/HvxGsPqwtYNTCNEt9" class="btn btn-primary scrollto" target='_blank' rel="noreferrer">Google maps</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
  }
  
  export default Location;