import Neela from './services/NelaScripts';
import InvitationPage from './pages/InvitationPage';
import LandingPage from './pages/LandingPage';

import axios from "axios";

import { useState, useEffect } from "react";

function App() {
  var [error, setError] = useState("No loaded");
  var [invitation, setInvitation] = useState(null);

  var getInvitation = (invitationId) => {
    axios.get('https://app-azure-function-wedding-220722194040.azurewebsites.net/api/GetInvitation?id=' + invitationId)
      .then(result =>  {
        setInvitation(result.data);
        setError(null);
      })
      .catch(err => {
        console.error(err);
        setError(err);
        Neela.preloader();
      });
  }

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const invitationId = params.get('id');

    if (!invitationId) {
      setError("No invitation ID.");
      Neela.preloader();
    } else {
      getInvitation(invitationId);
    }
  }, []);

  return (
    error ? <LandingPage/> : <InvitationPage weddingInfo={invitation}/>
  );
}

export default App;
